const config = {
  siteTitle: "Emergent Tapestry", // Site title.
  siteTitleShort: "Emergent Tapestry", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Emergent Tapestry", // Alternative site title for SEO.
  siteUrl: "https://emergenttapestry.com", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "The blog of Ali Rehmatullah", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Emergent Tapestry RSS Feed", // Title of the RSS feed
  siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  googleAnalyticsID: "G-EYZDWLJHM5", // GA tracking ID.
  disqusShortname: "https-vagr9k-github-io-gatsby-advanced-starter", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 7, // Amount of posts displayed per listing page.
  userName: "Ali Rehmatullah", // Username to display in the author segment.
  userEmail: "", // Email used for RSS feed's author segment
  userTwitter: "https://twitter.com/Ali_Rehmatullah", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "United States", // User location to display in the author segment.
  userAvatar: "", // User avatar to display in the author segment.
  userDescription: "", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "Twitter",
      url: "https://twitter.com/Ali_Rehmatullah",
      iconClassName: "fa fa-twitter",
    },
  ],
  copyright: `Copyright © ${new Date().getFullYear()}`, // Copyright string for the footer of the website and RSS feed.
  themeColor: "#050609", // Used for setting manifest and progress theme colors.
  backgroundColor: "#050609", // Used for setting manifest background color.
}

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = ""
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`

module.exports = config
