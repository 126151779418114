import react from "react"
import { css, Global } from "@emotion/react"
import modernNormalize from "./modernNormalize"
import { colorPrimatives, fontFamilies } from "./theme"

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        ${modernNormalize}
        html, body {
          background-color: ${colorPrimatives.richBlack};
          font-family: ${fontFamilies.amiri};
          font-size: 18px;
        }
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        ol {
          padding: 0;
          margin: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${fontFamilies.tajawal};
          font-weight: 900;
          line-height: 1.3em;
        }

        ol,
        ul {
          list-style-position: inside;
        }

        p {
          font-size: 1rem;
          line-height: 1.6em;
        }

        svg {
          height: 1rem;
          flex-shrink: 0;
        }

        a {
          text-decoration: none;
        }

        q,
        blockquote {
          quotes: "“" "„" "‘" "‚";
        }

        pre {
          overflow-x: scroll;
          background-color: rgba(0, 0, 0, 0.1);
        }

        hr {
          border: none;
          height: 1px;
          background-color: ${colorPrimatives.tumbleweed};
          margin: 2rem 0;
        }

        figure {
          margin: 0;
        }
      `}
    />
  )
}

export default GlobalStyles
