import { css, useTheme, keyframes } from "@emotion/react"
import {
  fontFamilies,
  fontSizes,
  colorPrimatives,
  mediaQueries,
} from "../styles/theme"

export const IconButton = ({ css: cssProp, icon, ...props }) => {
  const theme = useTheme()

  return (
    <button
      type="button"
      {...props}
      css={css`
        background-color: transparent;
        border: none;
        padding: 0;
        line-height: 0;

        fill: ${theme.color.text};
        color: ${theme.color.text};

        &:hover {
          fill: ${colorPrimatives.tumbleweed};
          cursor: pointer;
        }

        ${cssProp}
      `}
    >
      {icon}
    </button>
  )
}

export const Button = ({ variation = "primary", children, ...props }) => {
  const theme = useTheme()

  return (
    <button
      type="button"
      {...props}
      css={css`
        display: block;
        background-color: ${colorPrimatives.rustyRed};
        border: none;
        padding: 12px 32px;
        font-family: ${fontFamilies.tajawal};
        font-weight: 700;
        color: ${colorPrimatives.white};

        &:hover {
          background-color: ${colorPrimatives.rubyRed};
          cursor: pointer;
        }
      `}
    >
      {children}
    </button>
  )
}
